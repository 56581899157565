import './index.css'

const TermsOfService = (): JSX.Element => {
  return (
    <>
      <div className="textContainer">
        <h1 className='title'>Terms of Service</h1>
        <p className='date'>Effective: 17 August 2022</p>
        <h1 id='welcome-to-real'>Welcome to Real!</h1>
        <p>A warm greetings from Real  d/b/a (&quot;Real,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). These Terms of Service (&quot;Terms&quot;) govern your access to and use of the Real website(s), our application programming interfaces (&quot;APIs&quot;), mobile app (the &quot;App&quot;), and any other software, tools, features, or functionalities provided on or in connection with our services; including, without limitation, using our services to view, explore, and edit; and using our tools, at your discretion, to connect directly with others to share, edit, view, record, or take photographs or videos.</p>
        <p>You, the person using the Service, are referred to in these Terms as the &quot;user,&quot; &quot;you,&quot; and &quot;your.&quot; If you are using the Service on behalf of a company or other legal entity, then &quot;you&quot; will also refer to that company or legal entity and you will represent and warrant that (a) you have the legal authority to bind that company or legal entity to these Terms and (b) you have the legal authority to bind that legal entity to these Terms.</p>
        <p><strong>As these Terms of Service contain important information that may affect your legal rights, we ask that you read</strong> <strong>them</strong> <strong>carefully.</strong></p>
        <p><strong>By accepting these terms and conditions and/or using our service, you agree to be bound by them, as well as all of the terms incorporated herein by reference.</strong></p>
        <p> <strong>PLEASE DO NOT ACCESS OR USE THE SERVICE IF YOU DO NOT AGREE TO THESE TERMS.</strong></p>
        <ul>
        <li><h3 id='user-account-registration'>1. User Account Registration </h3>
        </li>

        </ul>
        <h4 id='a-required-information'>     A. Required Information</h4>
        <p>Having your own personal User Account is required in order to use Real We need the following details to create your User Account:</p>
        <p>your age</p>
        <p>your email address</p>
        <p>your full name</p>
        <p>password</p>
        <p>You can&#39;t use the Real App without these components.</p>
        <p>The details you supply to create your User Account must be true and accurate representations of who you are.</p>
        <p>You further agree to maintain the secrecy of the password you use to access your User Account. In the event that you become aware of any unauthorized use of your User Account, you must immediately alert us by emailing us <a href='mailto:nxgentech2022@gmail.com'>here</a>.</p>
        <h4 id='b-limitation-on-age-and-consent-of-parents'>B. Limitation on Age and Consent of Parents</h4>
        <p>The minimum age to join Real is 13.</p>
        <p>Under no circumstances may a User Account be created for, nor may the Application be used by, anyone under the age of 13. If you are between the ages of 13 and 16, we may also need consent from a parent or legal guardian.</p>
        <p>If you are the legal guardian of a kid above the age of 13 and need to get in touch with us, please use the email <a href='mailto:nxgentech2022@gmail.com'>here</a>.</p>
        <ul>
        <li><h3 id='description-of-services'>2. Description of Services </h3>
        </li>

        </ul>
        <p>In order to use the Services from your mobile device, you must first download the Real Mobile Application. Through the Services, you may make and distribute content, (ii) access and engage with the content of others, (iii) remove content from your own account, and (iv) delete content.</p>
        <ul>
        <li><h3 id='share-and-create-your-own-content'>3. Share and Create Your Own Content </h3>
        </li>

        </ul>
        <p>With this feature you can:</p>
        <p>Snap a quick picture of yourself and your surroundings, then post it online.</p>
        <p>If you&#39;re sharing your picture with the world, you can to let people know the location it was taken, or when the photo is shared publicly;</p>
        <p>You may decide whether or not to make the image public or only show it to your followers;</p>
        <p>Complete your User Account by adding details about yourself (bio, photo, contact data, etc.).</p>
        <ul>
        <li><h3 id='display-the-work-of-other-contributors'>4. Display the Work of Other Contributors </h3>
        </li>

        </ul>
        <p>You can see other people&#39;s content when:</p>
        <p>The User and you are friends; The Content is shared publicly.</p>
        <ul>
        <li><h3 id='communication-with-other-users'>5. Communication With Other Users  </h3>
        </li>

        </ul>
        <p>By using these methods, you can interact  with other Users and their Content through:</p>
        <p>If a User chooses to openly post Content, then other Users may answer with visible and public content;</p>
        <ul>
        <li><h3 id='removal-of-content'>6. Removal of Content  </h3>
        </li>

        </ul>
        <p>Daily Content removal is at your discretion.</p>
        <p>Other Content removal requests on the same day may be sent <a href='mailto:nxgentech2022@gmail.com'>here</a>.</p>
        <ul>
        <li><h3 id='how-to-report-a-post-that-you-think-is-inappropriate'>7. How to Report a Post That You Think Is Inappropriate </h3>
        </li>

        </ul>
        <p>We have no general duty as a data storage provider to inspect the contents of any account. However, if you see a picture or piece of text on the site that you find offensive or illegal, you may report it to us or the User who posted it by sending an email <a href='mailto:nxgentech2022@gmail.com'>here</a>. </p>
        <p>In particular, please report any content that is sexually explicit or inappropriate for minors, promotes or encourages terrorism or other forms of violence, targets a specific group for violence, or encourages or promotes self-harm or suicide.</p>
        <ul>
        <li><h3 id='account-deletion'>8. Account Deletion </h3>
        </li>

        </ul>
        <p>For any reason, including but not limited to the following, we reserve the right to immediately suspend or terminate your User Account upon notice from us and without prior notice to you:</p>
        <p>Account suspension for any reason, including but not limited to: violation or attempted violation of the Terms and/or relevant legislation; unforeseen technical or security concerns; or extended periods of inactivity</p>
        <p>Roles and Responsibilities of the User</p>
        <p>When using the Application, you agree to comply with the requirements specified in these Terms of Use and with the laws in effect. Therefore, you pledge not to:</p>
        <p>Use Real if you&#39;re under 13 or if you&#39;re between 13 and 16 and don&#39;t have permission from a parent or legal guardian;</p>
        <p>Advertise or solicit goods or services, publish or enable the publication or transmission of spam, &quot;chain letters,&quot; &quot;pyramid schemes,&quot; or gather information, data, or Content about other Users without their consent while using Real for commercial or advertising reasons;</p>
        <p>Use the Real App in a deceptive, malicious, discriminatory, or otherwise unlawful manner, such as impersonating another person for personal or commercial gain; Harm or attempt to harm another person or group, including, but not limited to, bullying, harassment, promotion of violence, or other illegal or immoral behavior; When using the Application to represent others, it is important to remember that you are entirely responsible for the Content you choose to publish. If you&#39;re going to utilize someone else&#39;s photo or work on Real, you should get their permission first.</p>
        <p>Our policy is to immediately delete or temporarily or permanently stop access to any Content that is obviously unlawful or does not comply with these Terms, and we retain the right to do so at any time and without prior warning.</p>
        <ul>
        <li><h3 id='confidentiality-of-data-and-software-applications'>9. Confidentiality of Data and Software Applications </h3>
        </li>

        </ul>
        <p>Except for User-Generated Information, everything in the Application, including the code, visuals, databases, text, digital content, trademarks, and HTML elements, belongs to Real(meta-tags).</p>
        <p>You have a global, limited, non-exclusive, revocable license to use the Application only for sharing Content and interacting with other Users until your User Account is deleted.</p>
        <p>In light of this, you are not permitted to copy, distribute, reverse-engineer, disassemble, decompile, or utilize the Application in any way, shape, or form, unless expressly permitted to do so by us in writing. </p>
        <p>Additionally, you must not:</p>
        <p>Making data accessible to the public in whole or in part and largely in quantitative or qualitative terms via the use of the Application or its visible databases for commercial or other purposes, whether temporarily or permanently;</p>
        <p>Extraction or use of all or a substantial portion of the visible information of the Application in a manner that is obviously in excess of regular and private usage of the Application;</p>
        <p>Use any device, software, or routine to interfere or attempt to interfere with the proper working of the Application, or to impose a disproportionately large load on our infrastructure. This includes, but is not limited to, the Application&#39;s user interface, databases, Services, programs, source codes, algorithms, and methods.</p>
        <ul>
        <li><h3 id='intellectual-property-and-licenses'>10. Intellectual Property and Licenses </h3>
        </li>

        </ul>
        <p>When you use Real you may generate original content for which you have authorship and/or ownership rights.</p>
        <p>You agree to grant Real and its Users a royalty-free, non-exclusive, worldwide license in any manner for thirty (30) years with respect to any Content you post to the Application.</p>
        <p>To Real to host, store, reproduce, modify, adapt, display, publish, edit, distribute, and sublicense all or part of the Content in order to provide the Application Services to its Users, and to conduct marketing, communication, or commercial promotion activities of Real;</p>
        <p> To other Users to reproduce and share the Content on WhatsApp, Facebook, Twitter, SnapChat, Instagram, and more generally any social network or messaging application that may be interfaced with Real; </p>
        <ul>
        <li><h3 id='indemnification-warranty-exclusion-and-liability'>11. Indemnification, Warranty Exclusion and Liability </h3>
        </li>

        </ul>
        <p>Both the application and its accompanying services are distributed without any guarantees of any kind. As a result, we make no promises regarding them, including but not limited to:</p>
        <p>Whether or not our services meet your requirements; Whether or not the functioning of the Real application is uninterrupted, error-free, secure, and free from third-party infringement; Whether or not the information you acquire using our services is accurate and reliable.</p>
        <p>You also understand that there are inherent dangers in using the Internet or any other data transmission network, such as the potential for the data to be misappropriated or infected by malicious software. Real further disclaims all responsibility for any information lost, changed, or lost time or opportunity.</p>
        <p>Whatever the case may be, we&#39;ll only be on the hook for your actual out-of-pocket costs, not any resulting &quot;punitive&quot; or &quot;contingent&quot; losses.</p>
        <p>To the extent authorized by law, we also reserve the right to change, suspend, discontinue, or restrict access to all or any portion of our Platform at any time for commercial and operational reasons.</p>
        <ul>
        <li><h3 id='alterations-to-the-current-terms-of-service'>12. Alterations to the current Terms of Service </h3>
        </li>

        </ul>
        <p>Real may update these Terms of Service at any moment at its discretion. The updated Terms of Service will be made available via the pages of the Application after they have been made public by Real.</p>
        <ul>
        <li><h3 id='miscellaneous'>13. Miscellaneous </h3>
        </li>

        </ul>
        <p>If any term is held by a court of competent jurisdiction to be invalid, unenforceable, or inapplicable, the validity, enforceability, and applicability of the remaining sections and agreements hereof shall not in any way be affected or impaired thereby. A valid provision that most closely approximates the economic aim sought by the Parties should replace the invalid provision. The Parties&#39; agreement is comprised of this Agreement and the Privacy Policy.</p>
        <ul>
        <li><h3 id='miscellaneous'>14. Contact </h3> </li>
        
        </ul>
        <p>If you have any questions or concerns regarding these Terms of Service you may contact us <a href='mailto:nxgentech2022@gmail.com'>here</a>.</p>
      </div>
    </>
  );
}

export default TermsOfService;