import { useEffect } from 'react';
const Download = (): JSX.Element => {
  useEffect(()=>{
    if(/android/i.test(navigator.userAgent)){
      window.location.replace('https://play.google.com/store/apps/details?id=xyz.centlkjowndcrr.r3al')
    }
    if(/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)){
      window.location.replace('https://testflight.apple.com/join/k9zUvRm1')
    }
  },[])
  return (
    <>
      <div style={{
        height: '100vh'
      }}>
      </div>
    </>
  );
}

export default Download;