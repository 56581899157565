import {
  AppBar,
  Box,
  BoxProps,
  Menu,
  Slide,
  Stack,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";

import LogoImage from '../../logo.png';
import TwitterIcon from '../../assets/images/icon_twitter.png';
import DiscordIcon from '../../assets/images/icon_discord.png';
import InsIcon from '../../assets/images/icon_ins.png';
import TikTokIcon from '../../assets/images/icon_tiktok.png';
import MentionIcon from '../../assets/images/icon_mention.png';
import { useState } from "react";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll = ({ window, children } : Props) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface IconButtonProps extends BoxProps {
  imageUrl: string;
}

const IconButton = styled('a', {
  shouldForwardProp: (prop) => prop !== 'imageUrl',
})<IconButtonProps>(({ theme, imageUrl }) => ({
  width: 48,
  height: 48,
  cursor: 'pointer',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  display: 'block',
}))

const CustomMenuItem = styled('a')(() => ({
  width: '100%',
  fontSize: '24px',
  lineHeight: '90px',
  color: '#FFFFFF',
  fontFamily: 'SFMedium',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'block',
  textDecoration: 'none',
  '&:hover': {
    color: '#9986FF',
  }
}))

const DownLoadBtn = styled('span')(()=>({
  display: 'inline-block',
  width: '124px',
  height: 36,
  background: '#5c3dFF',
  marginRight: '5px',
  borderRadius: '30px',
  fontSize: '14px',
  fontFamily:'SFBold',
  textAlign: 'center',
  lineHeight:'36px'
}))

const CustomMenuItemMobile = styled('a')(() => ({
  width: '100%',
  fontSize: '16px',
  lineHeight: '68px',
  color: '#FFFFFF',
  fontFamily: 'SFMedium',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'block',
  textDecoration: 'none',
}))

const Appbar = (): JSX.Element => {
  const [navBtn, setNavBtnClassNames] = useState('iconUl menu');
  const [navModel, setNavModelClassNames] = useState('nav-model');

  const [navBtnMobile, setNavBtnClassNamesMobile] = useState('iconUl menu');
  const [navModelMobile, setNavModelClassNamesMobile] = useState('nav-model');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorElMobile, setAnchorElMobile] = useState<null | HTMLElement>(null);
  const openMobile = Boolean(anchorElMobile);

  const matchesUpLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const matchesUpMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const matchesUpXs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtn.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNames('iconUl menu active opened');
      setNavModelClassNames('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNames('iconUl menu');
      setNavModelClassNames('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorEl(event.currentTarget);
    console.log('');
  };
  const handleMenuClose = () => {
    const isActive = navBtn.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNames('iconUl menu active opened');
      setNavModelClassNames('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNames('iconUl menu');
      setNavModelClassNames('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorEl(null);
  };

  const changeNavStatus = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtn.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNames('iconUl menu active opened');
      setNavModelClassNames('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
      handleMenuClick(event);
    } else {
      setNavBtnClassNames('iconUl menu');
      setNavModelClassNames('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
      handleMenuClose();
    }
  }

  const handleMenuClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorElMobile(event.currentTarget);
  };
  const handleMenuCloseMobile = () => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorElMobile(null);
  };

  const changeNavStatusMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
      handleMenuClickMobile(event);
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
      handleMenuCloseMobile();
    }
  }

  const getSvgSize = () => {
    if (matchesUpXs && matchesUpMd && matchesUpLG) {
      return 60;
    } else if (matchesUpXs && matchesUpMd) {
      return 50;
    } else {
      return 36;
    }
  }


  const DownLoadFun = ()=>{

    console.log('%c🀅 ', 'color: #00e600; font-size: 20px;', navigator.userAgent);
    if(/android/i.test(navigator.userAgent)){
      window.open('https://play.google.com/store/apps/details?id=xyz.centlkjowndcrr.r3al')
      return
    }
    if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
          window.open('https://testflight.apple.com/join/k9zUvRm1')
        return
    }
  }

  return (
    <HideOnScroll>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="transparent" sx={{ boxShadow: '0 0px 0px #ccc !important', background: 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',paddingRight: '0!important' }}>
          <Toolbar sx={{
            paddingLeft: {
              xs: '12px !important',
              md: '28px !important',
              lg: '55px !important'
            },
            paddingRight: {
              xs: '12px !important',
              md: '28px !important',
              lg: '55px !important'
            },
            paddingTop: {
              xs: '12px',
              md: '18px',
              lg: '24px'
            },
            paddingBottom: {
              xs: '12px',
              md: '24px',
              lg: '32px'
            },
          }}>
            <Box sx={{
              cursor: 'pointer',
              height: {
                xs: 36,
                md: 50,
                lg: 60,
              }
            }} onClick={() => {}}>
              <img src={LogoImage} height="100%" alt="appbar logo" style={{ display: 'block', }} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {
              matchesUpLG ? <Stack direction="row" sx={{alignItems: 'center'}} spacing={4.5}>
                <Stack direction="row" spacing={3}>
                  <IconButton href="https://twitter.com/realapp_xyz" target="_blank" imageUrl={TwitterIcon} />
                  <IconButton href="https://discord.com/invite/etfrb9x37t" target="_blank" imageUrl={DiscordIcon} />
                  <IconButton href="https://www.instagram.com/realappxyz_/" target="_blank" imageUrl={InsIcon} />
                  <IconButton href="https://www.tiktok.com/@realapp_official" target="_blank" imageUrl={TikTokIcon} />
                  <IconButton href=" https://medium.com/@realapp_xyz" target="_blank" imageUrl={MentionIcon} />
                </Stack>
                <div className="header-menu icon">
                  <button className={navBtn} aria-label="Main Menu" onClick={changeNavStatus}>
                    <svg width="60" height="60" viewBox="0 0 100 100">
                      <path className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                  </button>
                </div>
              </Stack> : <Stack direction="row" spacing={1.5}>
                {
                  matchesUpSm ? <></> : <DownLoadBtn onClick={DownLoadFun}>Download</DownLoadBtn>
                }
                <div className="header-menu-mobile icon" style={{ position: 'relative', zIndex: 1400, }}>
                  <button className={navBtnMobile} aria-label="Main Menu" onClick={changeNavStatusMobile}>
                    <svg width={getSvgSize()} height={getSvgSize()} viewBox="0 0 100 100">
                      <path className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                  </button>
                </div>
              </Stack>
            }
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          id="appbar-menu"
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
          elevation: 0,
          sx: {
            width: 656,
            overflow: 'hidden',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '24px',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(8px)',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              // backgroundColor: 'rgba(0, 0, 0, 0.8)',
              // filter: 'blur(6px)',
              // backdropFilter: 'blur(6px)',
              borderRadius: '24px',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <CustomMenuItem
            href="https://docs.google.com/document/d/1zbIhUuzK_uGauUQn2GoLiI0kQz4NsYHasvHBxPWKvqU/edit"
            target="_blank"
          >
            FAQ
          </CustomMenuItem>
          <CustomMenuItem
            href="https://teamup.com/ks6jt2qqb2su3fcurs"
            target="_blank"
          >
            Community Calendar
          </CustomMenuItem>
          <CustomMenuItem
            href="https://airtable.com/shrf26IUcUkARhd12"
            target="_blank"
          >
            Ambassador Program
          </CustomMenuItem>
          
          {/* <CustomMenuItem
            href="https://airtable.com/shruJ9WN0oeMTL0gC"
            target="_blank"
          >
            3D NFT Lens Partnership
          </CustomMenuItem>
          <CustomMenuItem
            href="https://airtable.com/shr1ewmk9scQAGsAE"
            target="_blank"
          >
            REAL Meetverse Partnership
          </CustomMenuItem>
          <CustomMenuItem
            href="https://airtable.com/shrySbIDGOmD4PKrs"
            target="_blank"
          >
            Branding with REAL
          </CustomMenuItem>
          <CustomMenuItem
            href="https://airtable.com/shrgEm7DqJaQqMhtR"
            target="_blank"
          >
            Other Partnership Proposals
          </CustomMenuItem> */}
          <CustomMenuItem
            href="https://airtable.com/shrmIUMKHWqkKZHZL"
            target="_blank"
          >
            Media & AMA Contact
          </CustomMenuItem>
          <CustomMenuItem
            href="/privacy"
            target="_blank"
          >
            Privacy Policy
          </CustomMenuItem>
          <CustomMenuItem
            href="/service"
            target="_blank"
          >
            Terms of Service
          </CustomMenuItem>
        </Menu>

        <Menu
          anchorEl={anchorElMobile}
          id="appbar-menu-mobile"
          open={openMobile}
          onClose={handleMenuCloseMobile}
          onClick={handleMenuCloseMobile}
          PaperProps={{
          elevation: 0,
          sx: {
            width: '100vw',
            height: '100vh',
            left: '0px !important',
            top: '0px !important',
            maxWidth: '100% !important',
            maxHeight: '100% !important',
            overflow: 'visible',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(8px)',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              // backgroundColor: 'rgba(0, 0, 0, 0.75)',
              // filter: 'blur(10px)',
              // backdropFilter: 'blur(6px)',
              borderRadius: '24px',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <CustomMenuItemMobile
            href="https://docs.google.com/document/d/1zbIhUuzK_uGauUQn2GoLiI0kQz4NsYHasvHBxPWKvqU/edit"
            target="_blank"
          >
            FAQ
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="https://teamup.com/ks6jt2qqb2su3fcurs"
            target="_blank"
          >
            Community Calendar
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="https://airtable.com/shrf26IUcUkARhd12"
            target="_blank"
          >
            Ambassador Program
          </CustomMenuItemMobile>
          {/* <CustomMenuItemMobile
            href="https://airtable.com/shruJ9WN0oeMTL0gC"
            target="_blank"
          >
            3D NFT Lens Partnership
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="https://airtable.com/shr1ewmk9scQAGsAE"
            target="_blank"
          >
            REAL Meetverse Partnership
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="https://airtable.com/shrySbIDGOmD4PKrs"
            target="_blank"
          >
            Branding with REAL
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="https://airtable.com/shrgEm7DqJaQqMhtR"
            target="_blank"
          >
            Other Partnership Proposals
          </CustomMenuItemMobile> */}
          <CustomMenuItemMobile
            href="https://airtable.com/shrmIUMKHWqkKZHZL"
            target="_blank"
          >
            Media & AMA Contact
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="/privacy"
            target="_blank"
          >
            Privacy Policy
          </CustomMenuItemMobile>
          <CustomMenuItemMobile
            href="/service"
            target="_blank"
          >
            Terms of Service
          </CustomMenuItemMobile>
          <Box
            className="header-menu-mobile icon"
            sx={{
              position: 'absolute',
              top: {
                xs: '12px',
                md: '18px',
                lg: '24px'
              },
              right: {
                xs: '12px !important',
                md: '28px !important',
                lg: '55px !important'
              },
            }}
          >
            <button className={navBtnMobile} aria-label="Main Menu" onClick={changeNavStatusMobile}>
              <svg width={getSvgSize()} height={getSvgSize()} viewBox="0 0 100 100">
                <path className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="line line2" d="M 20,50 H 80" />
                <path className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </Box>
        </Menu>
      </Box>
    </HideOnScroll>
  );
}

export default Appbar;
