import { Box, styled } from "@mui/material";

import FooterBg from '../../assets/images/footer-bg.png';

const FooterBox = styled(Box)(({theme}) =>({
  width: '100%',
  height: 90,
  [theme.breakpoints.down('sm')]: {
    height: 60,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${FooterBg})`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
}))

const Footer = (): JSX.Element => {
  return (
    <>
      <FooterBox>
        <Box
          sx={{
            fontSize: {
              xs: '12px',
              sm: '16px',
              md: '18px',
            },
            lineHeight: {
              xs: '16px',
              sm: '20px',
              md: '26px',
            },
            textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: 'SFMedium',
            padding: '0 20px',
          }}
        >
          <Box>
            <Box sx={{
              display: {
                xs: 'none',
                md: 'none',
                lg:'flex'
              },
              alignItems: 'center'
            }} className="pc">
            <img style={{
              width: '.36rem',
              marginRight: '.05rem'
            }} src={require('../../assets/images/email.png')} alt="" />nxgentech2022@gmail.com <Box sx={{
              width: '1px',
              height: '22px',
              background: 'transparent',
              display: 'inline-block',
              margin: '0 15px'
            }} className="shu"></Box>
            <img style={{
              width: '.36rem',
              marginRight: '.05rem'
            }} src={require('../../assets/images/phone.png')} alt="" />
          +6585046391</Box>
            <Box sx={{
              display: {
                xs: 'block',
                md: 'block',
                lg:'none'
              }
            }} className="phone">nxgentech2022@gmail.com <br/> +6585046391</Box>
          </Box>
        </Box>
      </FooterBox>
    </>
  );
}

export default Footer;
