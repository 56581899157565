/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-undef */
import { Avatar, Backdrop, Box, Button, Container, Fade, Grid, Modal, Popover, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import videoBg from '../../assets/images/video_bg.png' 
import iosCode from '../../assets/download/ios.png'
import androidCode from '../../assets/download/android.png'
import comingSoon from '../../assets/download/COMINGSOON.png'
import videoCover from '../../assets/images/video_cover.png'



const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  // pointerEvents: 'none',
  outline: 'none',
};

const popStyle = {
  zIndex: 9999,
  height: 'auto',
  pointerEvents: 'none',
  
  // marginTop: '8px',
  '.MuiPaper-root':{
    marginTop: '8px',
    overflow: 'inherit!important',
    '::before':{
      content:'""',
      width: '10px',
      height:'10px',
      background: '#fff',
      position: 'absolute',
      top:'0px',
      left:'50%',
      transform: 'translate(-50%, -50%) rotate(45deg)'
    }
  }
}

const ListItemStyle = styled('div')(({theme}) => ({
  width: '100%',
  fontSize: '32px',
  lineHeight: '1.1',
  letterSpacing:'.5px',
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'left',
  
  '.contain':{
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  '.text': {
    fontSize: '14px',
    fontFamily: 'SFMedium',
    flex: 1,
  },
  '.avatar':{
    width: '48px',
    height: '48px',
    marginRight: '15px',
    marginBottom: '6px',
    '&.pc':{
      display: 'none'
    }
  },
  '.br':{
    display: 'none'
  },
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    padding:'0 30px',
    lineHeight: '1',
    textAlign: 'left',
    paddingLeft: '0px',
    '.text': {
      fontSize: '14px',
      textAlign: 'center',
    },
    '.br':{
      display: 'block'
    },
    '.contain':{
      flexDirection: 'column',
    },
    
  },
 
  [theme.breakpoints.up('md')]: {
    width: '50%',
    padding:'0 30px',
    marginBottom: '40px',
    '.text': {
      fontSize: '20px',
      lineHeight: '1'
    },
    '.avatar':{
      marginBottom: '20px',
      width: '80px',
      height: '80px',
    },
    '.contain':{
      width: '100%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    width: '25%',
    padding:'0 20px',
    justifyContent: 'flex-start',
    '.text': {
      fontSize: '22px',
      lineHeight: '1.4',
    },
    '.avatar':{
      marginBottom: '26px',
      width: '80px',
      height: 80
    },
  },
  [theme.breakpoints.up('xl')]: {
    '.text': {
      fontSize: '24px',
    },
  },
}));
const DownloadStyle = styled('div')(({theme}) => ({
  marginBottom: '30px',
  marginTop: '30px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  '.btn':{
    fontSize: '20px',
    lineHeight: '53px',
    display:'flex',
    alignItems: 'center',
    color: '#fff',
    background: '#5C3DFF',
    borderRadius: '60px',
    fontFamily: 'SFDisplay',
    // padding: '7px 50px',
    width: 200,
    height: 56,
    // margin: 'auto',
    textTransform: 'none',
    ':hover':{
      background: '#5C3DFF',
    },
    'svg': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
      marginTop: '20px',
      width: 280,
      height: 80,
      'svg': {
        width: 28,
        height: 28,
      },
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0',
      marginTop: '60px',
      fontSize: '36px',
      // padding: '15px 50px',
      width: 320,
      height: 90,
      'svg': {
        width: '36px',
        height: '36px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '0',
      fontSize: '40px',
      // padding: '15px 50px',
      width: 370,
      height: 104,
      'svg': {
        width: '40px',
        height: '40px',
      },
    }
  }
}));

const PtBtnModel = styled('div')(({theme}) => ({
  fontSize: '32px',
  lineHeight: '44px',
  color: '#000',
  width: '100vw',
  maxWidth: '1200px',
  // pointerEvents: 'none',
  // '*':{
  //   pointerEvents: 'none',
  // },
  '.model_title': {
    fontSize: '80px',
    lineHeight: 1.2,
    color: 'rgb(255, 255, 255)',
    // backgroundColor: '#5C3DFF',
    padding: '10px',
    fontFamily: 'SFBold',
    textAlign: 'center'
  },
  '.model_content':{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0 10px',
    '.cel': {
      // padding: '40px',
      // background: '#fff',
      // borderRadius: '20px',
      margin: '20px 15px',
      // paddingBottom: '20px',
      'h6':{
        color: '#000',
        lineHeight: 1.5,
        margin: 0,
        marginTop: '10px',
        fontSize: '23px',
        textAlign: 'center',
        fontFamily: 'SFDisplay',
      },
      '.pic': {
        // width: '140px',
        height:'80px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.link':{
          width: '260px'
        }
      },

    }
  },
}));


const Features = (): JSX.Element => {


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const videoCotr = useRef<HTMLVideoElement>(null)

  useEffect(()=>{
    window.addEventListener('scroll', ()=>{
      if(open) {handleClose();handlePopoverClose()}
      if(videoCotr.current){
        const videoTop = videoCotr.current.getBoundingClientRect().top;
        if(videoTop < window.innerHeight){
          if(videoCotr.current.paused){
            videoCotr.current.play()
          }
        }
      }
      // console.log('%c🀄︎ ', 'color: #00e600; font-size: 20px;', videoBox.current?.getBoundingClientRect());
    })
  })

  const DownLoadFun = ()=>{
    if(/android/i.test(navigator.userAgent)){
      window.open('https://play.google.com/store/apps/details?id=xyz.centlkjowndcrr.r3al')
      return
    }
    if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
          window.open('https://testflight.apple.com/join/k9zUvRm1')
        return
    }
    handleOpen()
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: any) => {
    console.log(123123123)
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popopen = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box className="features" sx={{
        paddingTop: '80px',
        position: 'relative',
        zIndex: 1,
        height: {
          sm: 'auto',
          md: '100vh'
        }
      }}>
        <Container maxWidth={false}
          sx={{
            padding: {
              xl: '0 300px',
              lg: '0 200px',
              md: '0 100px',
              sm: '0 40px',
              xs: '0 20px'
            }
          }}>
          <Grid container justifyContent={'center'} flexDirection={'column'} alignItems='center'>
              <Typography  sx={{
                fontSize: {
                  xl: '90px',
                  lg: '80px',
                  md: '60px',
                  sm: '40px',
                  xs: '40px'
                },
                textAlign: {
                  xs: 'left',
                  sm: 'center'
                },
                width: '100%',
             
                fontFamily: 'SFBold',
                marginBottom: {
                  xs: '40px',
                  sm: '60px',
                  lg: '120px'
                }
              }} variant="h1" component="div" gutterBottom>
              Features 
              </Typography>
              <Box className="list" sx={
                {
                  display: 'flex',
                  flexWrap: 'wrap',
                }
              }>
                <ListItemStyle>
                  <div className="contain">
                    <Avatar className="avatar" alt="" src={require('../../assets/images/features_icon1.png')}/>
                    <Typography variant="h6" className="text" gutterBottom component="div">
                    Wallet connection to access your NFTs
                    </Typography>
                  </div>
                </ListItemStyle>
                <ListItemStyle>
                  <div className="contain">
                    <Avatar className="avatar" alt="" src={require('../../assets/images/features_icon2.png')}/>
                    <Typography variant="h6" className="text" gutterBottom component="div">
                    Real-time video recording and 3D NFT lenses
                    </Typography>
                  </div>
                </ListItemStyle>
                <ListItemStyle>
                  <div className="contain">
                    <Avatar className="avatar" alt="" src={require('../../assets/images/features_icon3.png')}/>
                    <Typography variant="h6" className="text" gutterBottom component="div">
                    Photo editing with PFP NFT 
                    </Typography>
                  </div>

                </ListItemStyle>
                <ListItemStyle>
                  <div className="contain">
                    <Avatar className="avatar" alt="" src={require('../../assets/images/features_icon4.png')}/>
                    <Typography variant="h6" className="text" gutterBottom component="div">
                    Connect like-minded NFT holders
                    </Typography>
                  </div>
                </ListItemStyle>
              </Box>
              <DownloadStyle className="download">
                <Button className="btn" variant="contained" onClick={DownLoadFun}>
                Download&nbsp;&nbsp;&nbsp;
                  <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2207" data-spm-anchor-id="a313x.7781069.0.i3">
                    <path d="M921.6 882.688H142.336c-24.576 0-44.032 19.456-44.032 44.032s19.456 44.032 44.032 44.032H921.6c24.576 0 44.032-19.456 44.032-44.032 0-23.552-19.456-44.032-44.032-44.032zM502.784 806.912c8.192 8.192 19.456 12.288 29.696 11.264 10.24 0 21.504-3.072 29.696-11.264l266.24-266.24c15.36-15.36 15.36-40.96 0-56.32s-40.96-15.36-56.32 0L570.368 686.08V89.088c0-21.504-17.408-39.936-39.936-39.936s-39.936 17.408-39.936 39.936v592.896L292.864 484.352c-15.36-15.36-40.96-15.36-56.32 0s-15.36 40.96 0 56.32l266.24 266.24z" p-id="2208" fill="#ffffff"></path>
                  </svg>
                </Button>

              </DownloadStyle>
            </Grid>
     
        </Container>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          background: 'rgba(0,0,0,.8)',
          backdropFilter: 'blur(8px)',
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <PtBtnModel>
              <div className="model_title">
                Scan the QR code to download REAL App
              </div>
              <div className="model_content">
                <div className="cel">
                  <a className="pic link" href="https://testflight.apple.com/join/k9zUvRm1" target='_blank'>
                    <img src={require('../../assets/images/testflight.png')} height="100%" alt=""/>
                  </a>
                </div>
                <div className="cel">
                  <a className="pic link" href="https://play.google.com/store/apps/details?id=xyz.centlkjowndcrr.r3al" target="_blank">
                    <img src={require('../../assets/images/googleplay.png')} height="100%" alt=""/>
                  </a>
                </div>
                <div className="cel">
                  <Button className="pic" variant="contained" aria-describedby='mouse-over-popover' onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                    <img src={require('../../assets/images/code.png')} height="100%" alt=""/>
                  </Button>
                </div>

            
                <Popover
                  id="mouse-over-popover"
                  open={popopen}
                  anchorEl={anchorEl}
                  sx={popStyle}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  anchorReference={'anchorEl'}
                  // disableRestoreFocus
                >
                  <Box sx={{display:'flex' }}>
                    <img src={require('../../assets/images/download.png')} height="180" alt=""/>
                  </Box>
                </Popover>
              </div>
            </PtBtnModel>
          </Box>
        </Fade>
      </Modal>

    </>
  );
}

export default Features;
