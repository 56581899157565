import { Box, BoxProps, Container, Grid, Stack, styled, Theme, Typography, useMediaQuery } from "@mui/material";

import RightIcon from '../../assets/images/icon_right.png';
import WhiteIcon from '../../assets/images/icon_right_white.png';
import PartnershipImage from '../../assets/videos/partnership.webp';
import BgImage from '../../assets/images/bg.png';
import TwitterIcon from '../../assets/images/icon_twitter.png';
import DiscordIcon from '../../assets/images/icon_discord.png';
import InsIcon from '../../assets/images/icon_ins.png';
import TikTokIcon from '../../assets/images/icon_tiktok.png';
import MentionIcon from '../../assets/images/icon_mention.png';

const ShipContainer = styled('a')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    height: 83,
  },
  [theme.breakpoints.down('lg')]: {
    height: 83,
  },
  [theme.breakpoints.down('md')]: {
    height: 83,
  },
  [theme.breakpoints.down('sm')]: {
    height: 60,
    padding: '0 8px 0 20px',
  },
  [theme.breakpoints.up('xl')]: {
    height: 83,
  },
  width: '100%',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  border: '0.83px solid #979797',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px 0 40px',
  marginBottom: '20px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#9986FF',
  },
  textDecoration: 'none',
}));

const ImageContainer = styled('img')(() => ({}))

const ShipText = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '24px'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '24px'
  },
  color: '#000000',
  fontFamily: 'SFMedium',
}))

const RightIconDiv = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('xl')]: {
    width: 66,
    height: 66,
  },
  [theme.breakpoints.down('lg')]: {
    width: 66,
    height: 66,
  },
  [theme.breakpoints.down('md')]: {
    width: 66,
    height: 66,
  },
  [theme.breakpoints.down('sm')]: {
    width: 42,
    height: 42,
  },
  [theme.breakpoints.up('xl')]: {
    width: 66,
    height: 66,
  },
  backgroundImage: `url(${RightIcon})`,
  backgroundPosition: 'center',
  backgroundSize: '100% 100%',
}))

const Title = styled(Typography)(({theme}) => ({


  // marginBottom: {
  //   xs: '20px',
  //   sm: '60px',
  //   lg: '120px'
  // }




  fontSize: '40px',
  lineHeight: '40px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '40px',
    lineHeight: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '60px',
    lineHeight: '64px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '80px',
    lineHeight: '84px',
  },
  [theme.breakpoints.up('xl')]: {
    marginBottom: '40px',
    fontSize: '90px',
    lineHeight: '100px',
  },
  marginBottom: '20px',
  color: '#FFFFFF',
  fontFamily: 'SFBold',
}))

const SubTitle = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '28px',
    lineHeight: '34px',
  },
  marginBottom: '20px',
  color: '#9986FF',
  fontFamily: 'SFMedium',
}))

const Tips = styled(Typography)(({theme}) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '28px',
    // lineHeight: '40px',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    // lineHeight: '40px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    // lineHeight: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    // lineHeight: '14px',
    marginTop: '20px',
    marginBottom: '40px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '20px',
    // lineHeight: '34px',

  },
  lineHeight: '1.2',
  marginTop: '40px',
  marginBottom: '40px',
  color: '#FFFFFF',
  fontFamily: 'SFMedium',
}))

const JoinUs = styled('a')(({theme}) => ({
    fontSize: '20px',
    lineHeight: '53px',
    display:'flex',
    alignItems: 'center',
    color: '#fff',
    background: '#5C3DFF',
    borderRadius: '60px',
    fontFamily: 'SFDisplay',
    textDecoration: 'none',
    textAlign: 'center',
    justifyContent: 'center',
    // padding: '7px 50px',
    width: '100%',
    height: 60,
    // margin: 'auto',
    textTransform: 'none',
    ':hover':{
      background: '#5C3DFF',
    },
    'svg': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
      width: '100%',
      height: 80,
      'svg': {
        width: 28,
        height: 28,
      },
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0',
      fontSize: '26px',
      // padding: '15px 50px',
      width: 460,
      height: 82,
      'svg': {
        width: '28px',
        height: '28px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '0',
      fontSize: '32px',
      // padding: '15px 50px',
      width: 560,
      height: 102,
      'svg': {
        width: '34px',
        height: '34px',
      },
    }

  // [theme.breakpoints.down('xl')]: {
  //   width: 370,
  //   height: 120,
  //   fontSize: '44px',
  // },
  // [theme.breakpoints.down('lg')]: {
  //   width: 370,
  //   height: 120,
  //   fontSize: '44px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   width: 370,
  //   height: 120,
  //   fontSize: '44px',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   width: 200,
  //   height: 56,
  //   fontSize: '20px',
  //   margin: 'auto',
  //   paddingLeft: '8px',
  // },
  // [theme.breakpoints.up('xl')]: {
  //   width: 370,
  //   height: 120,
  //   fontSize: '44px',
  // },
  // borderRadius: '71px',
  // backgroundColor: '#5C3DFF',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // color: '#FFFFFF',
  // fontSize: '44px',
  // cursor: 'pointer',
  // fontFamily: 'SFDisplay',
  // textDecoration: 'none',
}))

const WhiteRightIcon = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('xl')]: {
    width: 66,
    height: 66,
    marginLeft: '16px',
  },
  [theme.breakpoints.down('lg')]: {
    width: 66,
    height: 66,
    marginLeft: '16px',
  },
  [theme.breakpoints.down('md')]: {
    width: 66,
    height: 66,
    marginLeft: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 40,
    marginLeft: '4px',
  },
  [theme.breakpoints.up('xl')]: {
    width: 66,
    height: 66,
    marginLeft: '16px',
  },
  backgroundImage: `url(${WhiteIcon})`,
  backgroundPosition: 'center',
  backgroundSize: '100% 100%',
}))


interface IconButtonProps extends BoxProps {
  imageUrl: string;
}

const IconButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageUrl',
})<IconButtonProps>(({ theme, imageUrl }) => ({
  width: 50,
  height: 50,
  cursor: 'pointer',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
}))

const Partnership = (): JSX.Element => {
  const matchesDownLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const matchesDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{ paddingTop: '78px',position: 'relative' }}
      >
        <Container maxWidth={false}
          sx={{
            padding: {
              xl: '0 300px',
              lg: '0 200px',
              md: '0 100px',
              sm: '0 40px',
              xs: '0 20px'
            },
            paddingBottom: {
              xs: '150px !important',
              sm: '200px !important'
            },
            backgroundImage: `url(${BgImage})`,
            backgroundSize: {
              sm: '1000px 1600px',
              xs: '400px auto',
            },
            backgroundPosition: {
              sm: 'right -60px bottom -350px',
              xs: 'right 0px bottom -120px',
            },
            backgroundRepeat: 'no-repeat',
            position: 'relative'
          }}
        >
          <Grid container direction={ matchesDownLG ? 'column' : 'row' } spacing={{xs: 5, md: 24}}>
            <Grid item xs={12} md={6} lg={7}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title>
                  Partnership
                </Title>
                <SubTitle>
                  We currently have four partnership programs,
                  <br />
                  please click the links below to learn more:
                </SubTitle>
                <ShipContainer
                  href="https://airtable.com/shruJ9WN0oeMTL0gC"
                  target="_blank"
                >
                  <ShipText>
                    3D NFT Lens Partnership
                  </ShipText>
                  <RightIconDiv />
                </ShipContainer>
                <ShipContainer
                  href="https://airtable.com/shrmJlmyxMSxKfcF7"
                  target="_blank"
                >
                  <ShipText>
                    Sticker Partnership
                  </ShipText>
                  <RightIconDiv />
                </ShipContainer>
                <ShipContainer
                  href="https://airtable.com/shr1ewmk9scQAGsAE"
                  target="_blank"
                >
                  <ShipText>
                    REAL Meetverse Partnership
                  </ShipText>
                  <RightIconDiv />
                </ShipContainer>
                <ShipContainer
                  href="https://airtable.com/shrySbIDGOmD4PKrs"
                  target="_blank"
                >
                  <ShipText>
                    Branding with REAL
                  </ShipText>
                  <RightIconDiv />
                </ShipContainer>
                <Tips>
                  Whether you are an institution or an individual, you can apply as long as you meet our partner criteria.
                </Tips>
                <JoinUs href="https://airtable.com/shrgEm7DqJaQqMhtR">
                  Other Partnership Proposals&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <WhiteRightIcon /> */}
                  <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2799" width="128" height="128"><path d="M581.632 781.824L802.816 563.2H99.328a51.2 51.2 0 0 1 0-102.4h703.488l-221.184-218.624a51.2 51.2 0 0 1 0-72.192 46.592 46.592 0 0 1 68.096 0l310.272 307.2a55.296 55.296 0 0 1 0 74.752l-310.272 307.2a46.592 46.592 0 0 1-68.096 0 51.2 51.2 0 0 1 0-77.312z" fill="#ffffff" p-id="2800"></path></svg>
                </JoinUs>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImageContainer src={PartnershipImage} sx={{
                  marginLeft: {
                    xs: 16,
                    md: 0,
                  },
                  height: {
                    xs: 'auto',
                    md: 479,
                  },
                  width: {
                    xs: '70%',
                    md: 'auto',
                  }
                }} />
              </Box>
            </Grid>
          </Grid>
          {
            matchesDownSM?
            <Box
              sx={{
                position:'absolute',
                left:0,
                bottom: '4vh',
                width: '100%',
                // height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack direction="row" spacing={1.5}>
                <a href="https://twitter.com/realapp_xyz" target='_blank' rel="noreferrer" ><IconButton imageUrl={TwitterIcon} /></a>
                <a href="https://discord.com/invite/r8nvDzBa" target='_blank' rel="noreferrer" ><IconButton imageUrl={DiscordIcon} /></a>
                <a href="https://www.instagram.com/realappxyz_/" target="_blank" rel="noopener noreferrer"><IconButton imageUrl={InsIcon} /></a>
                <a href=" https://www.tiktok.com/@realapp_official" target="_blank" rel="noopener noreferrer"><IconButton imageUrl={TikTokIcon} /></a>
                <a href="https://medium.com/@realapp_xyz" target="_blank" rel="noopener noreferrer"><IconButton imageUrl={MentionIcon} /></a>
              </Stack>
            </Box>
            :''
          }
        </Container>
      </Box>
    </>
  );
}

export default Partnership;
