import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
// import Appbar from './components/Appbar';
// import Footer from './components/Footer';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Download from './pages/download';

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/service' element={<TermsOfService/>} />
          <Route path='/privacy' element={<PrivacyPolicy/>} />
          <Route path='/download' element={<Download/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
