/* eslint-disable jsx-a11y/alt-text */
import { Box, styled, Typography } from "@mui/material";
import volOpen from '../../assets/images/voice_open.png';
import volClose from '../../assets/images/voice_close.png'
import { useEffect, useRef } from "react";

const Title = styled(Box)(({ theme }) => ({
  width: '112px',
  height: 'auto',
  marginBottom: '12px',
  [theme.breakpoints.up('md')]: {
    width: '33.33%',
    marginBottom: '4.74%',
  },
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'SFBold',
  [theme.breakpoints.up('md')]: {
    fontSize: '70% !important',
  },
}))

const Banner = (): JSX.Element => {
  const bgVol = useRef<HTMLVideoElement>(null)
  const bannerBox = useRef<HTMLDivElement>(null);
  const titleBox = useRef<HTMLDivElement>(null);
  const videoBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setBannerStyle = () => {
      if (bannerBox.current && titleBox.current && videoBox.current && (window.innerWidth > 576 && window.innerWidth < 1200)) {
        bannerBox.current.classList.add('vertical-screen');
        titleBox.current.classList.add('vertical-screen-title-box');
        videoBox.current.classList.add('vertical-screen-video-box');
      }
    }

    if (window.orientation === 180 || window.orientation === 0) {
      setBannerStyle();
    }

    window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", function() {
      if (window.orientation === 180 || window.orientation === 0) {
        setBannerStyle();
      }
      if (window.orientation === 90 || window.orientation === -90 ){
        if (bannerBox.current && titleBox.current && videoBox.current) {
          bannerBox.current.classList.remove('vertical-screen');
          titleBox.current.classList.remove('vertical-screen-title-box');
          videoBox.current.classList.remove('vertical-screen-video-box');
        }
      }
    }, false);
  }, [])

  const volBtn = ()=>{
    if(bgVol.current){
      var sta = bgVol.current.paused;
      var ele = document.getElementById('volbtn')
      if(ele !== null){
        if(sta === true){
          // bgVol.current.muted = false;
          bgVol.current.play()

          // ele.setAttribute('background-image': `url(${volClose})`)
          ele.style.backgroundImage = 'url(' + volOpen + ')';

        } else {
          // bgVol.current.muted = true;
          bgVol.current.pause()
          // ele.setAttribute('background-image': `url(${volOpen})`)
          ele.style.backgroundImage = 'url(' + volClose + ')';
        }
      }

    }
  }
  return (
    <>
      <Box id="banner" sx={{ width: '100vw',position: 'relative' }}>
        <Box
          ref={bannerBox}
          sx={{
            width: '100vw',
            minHeight: {
              sm: 'auto',
              md: '100vh'
            },
            margin: 0,
            padding: {
              xl: '0 300px',
              lg: '0 200px',
              md: '0 100px',
              sm: '0 40px',
              xs: '0 20px'
            },
            display: 'flex',
            flexDirection: {
              xs: 'column-reverse',
              sm: 'row',
            },
            justifyContent: {
              xs: 'flex-start',
              sm: 'space-between',
            },
            alignItems: {
              xs: 'center',
              sm: 'flex-start',
            },
            paddingTop: {
              xs: '85px',
              sm: '10.3% !important',
            }
          }}
        >
          <Box
            ref={titleBox}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: {
                xs: 'center',
                sm: 'flex-start',
              },
              paddingTop: {
                xs: '12px',
                sm: '18%',
              },
            }}
          >
            <Title>
              <img src={require('../../assets/images/title.png')} alt="Title" style={{ display: 'block', width: '100%', }} />
            </Title>
            <SubTitle>
              Your Verified NFT Camera
            </SubTitle>
          </Box>
          <Box
            ref={videoBox}
            sx={{
              width: {
                xs: '94%',
                sm: '450px',
                xl: '560px',
              },
              height: 'auto',
            }}
          >
            <video ref={bgVol} style={{borderRadius: '12px'}} poster={require('../../assets/images/video_cover.png')} width="100%" muted playsInline autoPlay loop>
              <source
                src='https://real-upload.s3.amazonaws.com/video.mp4'
                type="video/mp4"
              />
            </video>
          </Box>
        </Box>
      </Box>
      <Box
      onClick={volBtn}
      id='volbtn'
      sx={{
        position: 'fixed',
        right: '20px',
        bottom: '50px',
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        zIndex: 99,
        background: `url(${volClose}) no-repeat center`,
        backgroundSize: '100%',
        // ':hover':{
        //   backgroundImage: `url(${volClose})`
        // }
      }}


      >
      </Box>
    </>
  );
}

export default Banner;
