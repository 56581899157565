import Appbar from "../../components/Appbar";
import Footer from "../../components/Footer";
import { Box, styled } from "@mui/material";
import Features from "../features";
import Partnership from "../Partnership";
import Banner from "../Banner";
import topBg from '../../assets/images/topBg.png'
import MTopBg from '../../assets/images/phone-bg.png'

// import BgImage from '../../assets/images/bg.png';

const CuntomContainer = styled(Box)(() => ({
  position: 'relative'
}))

const Home = (): JSX.Element => {
  return (
    <>
      <Appbar />
      <div>
        <CuntomContainer>
        {/* <Box sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          }}>
            <video src={require('../../assets/videos/BG.mp4')} width='100%' autoPlay muted ></video>
          </Box> */}
          <Box
          sx={{
            backgroundImage: {
              xs: `url(${MTopBg})`,
              sm: `url(${topBg})`
            },
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',

          }}
          >
            <Banner />
            <Features />
          </Box>
          <Partnership />
          
        </CuntomContainer>
      </div>
      <Footer />
    </>
  );
}

export default Home;
